<template>
  <div class="opage contact-page">
    <div class="op-title">
      <h2>联系我们</h2>
      <h3>just contact us now~</h3>
      <div class="mark">CONTACT US</div>
    </div>

    <div class="op-ma">
      <div class="the-title">
        <h3>联系方式</h3>
        <p>期待您的来电，我们将竭诚为您服务</p>
      </div>
      <ul class="list fx-h-l">
        <li>
          <h3>邮箱</h3>
          <p class="en">email</p>
          <p class="num">spyc-echo@outlook.com</p>
        </li>
      </ul>
      <div class="qcord">
        <h3>企业微信</h3>
        <img src="../assets/images/img-qcord.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
