<!--
 * @Author: long 349555788@qq.com
 * @Date: 2023-02-11 17:24:53
 * @LastEditors: long 349555788@qq.com
 * @LastEditTime: 2023-07-08 17:50:32
 * @FilePath: /spoint/src/components/About.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="opage about-page">
    <div class="op-title">
      <h2>关于我们</h2>
      <h3>About Us, we are the best</h3>
      <div class="mark">ABOUT US</div>
    </div>

    <div class="op-ma">
      <div class="obox">
        <div class="b-title">我们是谁</div>
        <div class="intro who">
          <p>
            言鈞網絡网络科技是专业的互联网技术定制团队 <br />
            为行业量身策划、定制、设计、研发您专属的互联网平台
            我们将商业目标、技术实现、用户体验融为一体，以为客户提高
            企业价值为使命。
          </p>
          <p>我们在北京多年的实战经验中，锤炼出全面稳健的核心竞争力：</p>
          <ul class="item-list">
            <li>秉持以内容为王的态度，追求精益求精的品质；</li>
            <li>顺应数字媒体发展趋势，敏锐捕捉尖端技术与热点项目；</li>
            <li>
              比传统媒体更懂技术，比数字媒体更懂内容，从创意策划到开发执行一体化服务，积累了丰富的整合营销经验；
            </li>
            <li>
              丰富的执行经验、创意策划、设计执行、开发维护、推广以及效果优化一条龙
            </li>
            <li>
              对于媒体类产品（小程序、公众号、CMS、设计）运营有深刻理解，能有效承载客户各种广告投放以及
              媒体运营需求。
            </li>
          </ul>
        </div>
      </div>

      <div class="obox">
        <div class="b-title">我们的服务</div>
        <div class="intro">
          <div class="our-fw">
            <div class="oitem">
              <h2>网站定制</h2>
              <p>网站、平台、电商、系统后台、社区</p>
            </div>
            <div class="oitem">
              <h2>移动端</h2>
              <p>Android/Ios APP、H5页面</p>
            </div>
            <div class="oitem">
              <h2>微信产品</h2>
              <p>公众号建设、小程序、小游戏、推广活动H5</p>
            </div>
            <div class="oitem">
              <h2>品牌推广</h2>
              <p>品牌Logo、VI、展台设计、设计物料</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
