<template>
  <div class="business-box fx-h-se" ref="slideWrap">
    <div
      class="obl fx-c obl-1"
      :class="{ show: obIndex === 0 }"
      @click="changeSlide(0)"
    >
      <!-- 主体内容 -->
      <div class="show-layer">
        <div class="bg"></div>
        <div class="content">
          <div class="index-num">01</div>
          <h1 class="m-title">网站高端定制，让您脱颖而出</h1>
          <div class="slogn">发现最适合产品气质的定制方案</div>
          <ul class="detail-list">
            <li>官网</li>
            <li>电商</li>
            <li>社区</li>
            <li>系统后台</li>
            <li>个性网站</li>
          </ul>
        </div>
        <div class="ide-fe">
          <div class="p-m"></div>
          <div class="p-1"></div>
          <div class="p-2"></div>
          <div class="p-3"></div>
          <div class="p-4"></div>
          <div class="p-5"></div>
          <div class="p-6"></div>
        </div>
        <div class="ide-dot">
          <div class="dot dot-1"></div>
          <div class="dot dot-2"></div>
          <div class="dot dot-3"></div>
          <div class="dot dot-4"></div>
        </div>
      </div>
      <!-- 默认状态 -->
      <div class="init-layer">
        <div class="index-num">01</div>
        <h1 class="m-title">网站定制</h1>
        <p class="slogn">官网、电商、系统后台、社区</p>
        <div class="icon-d"></div>
      </div>
    </div>
    <div
      class="obl fx-c obl-2"
      :class="{ show: obIndex === 1 }"
      @click="changeSlide(1)"
    >
      <!-- 主体内容 -->
      <div class="show-layer">
        <div class="bg"></div>
        <div class="content">
          <div class="index-num">02</div>
          <h1 class="m-title">智能时代，为您保驾护航</h1>
          <div class="slogn">IOS/Android APP、 H5</div>
          <ul class="detail-list">
            <li>IOS/Anroid 应用</li>
            <li>移动端H5</li>
            <li>响应式网站</li>
          </ul>
        </div>
        <div class="ide-fe">
          <div class="p-m"></div>
          <div class="p-1"></div>
          <div class="p-2"></div>
          <div class="p-3"></div>
          <div class="p-4"></div>
          <div class="p-5"></div>
          <div class="p-6"></div>
        </div>
      </div>
      <!-- 默认状态 -->
      <div class="init-layer">
        <div class="index-num">02</div>
        <h1 class="m-title">移动端</h1>
        <p class="slogn">APP、 H5、落地页</p>
        <div class="icon-d"></div>
      </div>
    </div>
    <div
      class="obl fx-c obl-3"
      :class="{ show: obIndex === 2 }"
      @click="changeSlide(2)"
    >
      <!-- 主体内容 -->
      <div class="show-layer">
        <div class="bg"></div>
        <div class="content">
          <div class="index-num">03</div>
          <h1 class="m-title">一站式搭建微信全生态</h1>
          <div class="slogn">涵盖微信全生态</div>
          <ul class="detail-list">
            <li>公众号定制</li>
            <li>小程序</li>
            <li>小游戏</li>
            <li>推广活动H5</li>
          </ul>
        </div>

        <div class="ide-fe">
          <div class="p-m"></div>
          <div class="p-1"></div>
          <div class="p-2"></div>
          <div class="p-3"></div>
          <div class="p-4"></div>
          <div class="p-5"></div>
          <div class="p-6"></div>
          <div class="p-7"></div>
          <div class="p-8"></div>
          <div class="p-9"></div>
        </div>
      </div>
      <!-- 默认状态 -->
      <div class="init-layer">
        <div class="index-num">03</div>
        <h1 class="m-title">微信产品</h1>
        <p class="slogn">公众号、小程序、小游戏、活动页</p>
        <div class="icon-d"></div>
      </div>
    </div>
    <div
      class="obl fx-c obl-4"
      :class="{ show: obIndex === 3 }"
      @click="changeSlide(3)"
    >
      <!-- 主体内容 -->
      <div class="show-layer">
        <div class="bg"></div>
        <div class="content">
          <div class="index-num">04</div>
          <h1 class="m-title">品牌运营, 让用户印象深刻</h1>
          <div class="slogn">更多用户发现您的产品</div>
          <ul class="detail-list">
            <li>品牌 LOGO VI</li>
            <li>线下活动</li>
            <li>设计物料</li>
          </ul>
        </div>

        <div class="ide-fe">
          <div class="p-m"></div>
          <div class="p-1"></div>
          <div class="p-2"></div>
          <div class="p-3"></div>
          <div class="p-4"></div>
          <div class="p-5"></div>
          <div class="p-6"></div>
          <div class="p-7"></div>
          <div class="p-8"></div>
          <div class="p-9"></div>
        </div>
      </div>
      <!-- 默认状态 -->
      <div class="init-layer">
        <div class="index-num">04</div>
        <h1 class="m-title">品牌运营</h1>
        <p class="slogn">VI、宣传、活动</p>
        <div class="icon-d"></div>
      </div>
    </div>

    <div class="dot-box">
      <span
        class="odot"
        v-for="item in 4"
        :key="item"
        :class="{ cur: obIndex === item - 1 }"
        @click="changeSlide(item - 1)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      obIndex: 0,
      timer: null,
    };
  },
  methods: {
    changeSlide(id) {
      clearTimeout(this.timer);
      this.obIndex = id;
      this.$emit("changeSlideId", id);
      this.$nextTick(() => {
        this.setScrollPos();
        this.autoPlay();
      });
    },
    setScrollPos() {
      const dom = this.$refs.slideWrap;
      const ow = dom.querySelectorAll(".obl")[0].clientWidth;
      dom.scrollLeft = ow * this.obIndex - dom.clientWidth / 2 + ow / 2;
    },
    autoPlay() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        console.log(this.obIndex < 4 ? this.obIndex++ : 0);
        this.changeSlide(this.obIndex < 4 ? this.obIndex++ : 0);
      }, 5000);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.changeSlide(0);
    });
    this.$emit("changeSlideId", 0);
  },
  destroyed() {
    clearTimeout(this.timer);
  },
};
</script>

<style scoped></style>
