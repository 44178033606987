<template>
  <div class="opage project-page">
    <div class="op-title">
      <h2>案例</h2>
      <h3>we do the best, we do the most</h3>
      <div class="mark">PROJECT LIST</div>
    </div>

    <div class="op-ma">
      <div class="oproject" v-for="(item, index) in lists" :key="index">
        <div class="the-title fx-h-l">
          <div class="index-num">
            {{ index + 1 >= 10 ? index + 1 : "0" + (index + 1) }}
          </div>
          <div class="intro">
            <h2>{{ item.title }}</h2>
            <p>{{ item.desc }}</p>
          </div>
        </div>
        <div class="pic">
          <img :src="item.picUrl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectPage",
  data() {
    return {
      lists: [
        {
          title: "Honey",
          desc: "陌生人社交/微信小程序/移动端",
          picUrl: require("../assets/project/project-1.png"),
        },
        {
          title: "斯巴鲁",
          desc: "陌生人社交/微信小程序/移动端",
          picUrl: require("../assets/project/project-2.png"),
        },
        {
          title: "方块迷航",
          desc: "cocos 小游戏/微信小游戏",
          picUrl: require("../assets/project/project-3.png"),
        },
        {
          title: "Logo Design",
          desc: "Logo 设计",
          picUrl: require("../assets/project/project-4.png"),
        },
      ],
    };
  },
};
</script>

<style scoped></style>
