var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"slideWrap",staticClass:"business-box fx-h-se"},[_c('div',{staticClass:"obl fx-c obl-1",class:{ show: _vm.obIndex === 0 },on:{"click":function($event){return _vm.changeSlide(0)}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"obl fx-c obl-2",class:{ show: _vm.obIndex === 1 },on:{"click":function($event){return _vm.changeSlide(1)}}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"obl fx-c obl-3",class:{ show: _vm.obIndex === 2 },on:{"click":function($event){return _vm.changeSlide(2)}}},[_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"obl fx-c obl-4",class:{ show: _vm.obIndex === 3 },on:{"click":function($event){return _vm.changeSlide(3)}}},[_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"dot-box"},_vm._l((4),function(item){return _c('span',{key:item,staticClass:"odot",class:{ cur: _vm.obIndex === item - 1 },on:{"click":function($event){return _vm.changeSlide(item - 1)}}})}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-layer"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"index-num"},[_vm._v("01")]),_c('h1',{staticClass:"m-title"},[_vm._v("网站高端定制，让您脱颖而出")]),_c('div',{staticClass:"slogn"},[_vm._v("发现最适合产品气质的定制方案")]),_c('ul',{staticClass:"detail-list"},[_c('li',[_vm._v("官网")]),_c('li',[_vm._v("电商")]),_c('li',[_vm._v("社区")]),_c('li',[_vm._v("系统后台")]),_c('li',[_vm._v("个性网站")])])]),_c('div',{staticClass:"ide-fe"},[_c('div',{staticClass:"p-m"}),_c('div',{staticClass:"p-1"}),_c('div',{staticClass:"p-2"}),_c('div',{staticClass:"p-3"}),_c('div',{staticClass:"p-4"}),_c('div',{staticClass:"p-5"}),_c('div',{staticClass:"p-6"})]),_c('div',{staticClass:"ide-dot"},[_c('div',{staticClass:"dot dot-1"}),_c('div',{staticClass:"dot dot-2"}),_c('div',{staticClass:"dot dot-3"}),_c('div',{staticClass:"dot dot-4"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"init-layer"},[_c('div',{staticClass:"index-num"},[_vm._v("01")]),_c('h1',{staticClass:"m-title"},[_vm._v("网站定制")]),_c('p',{staticClass:"slogn"},[_vm._v("官网、电商、系统后台、社区")]),_c('div',{staticClass:"icon-d"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-layer"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"index-num"},[_vm._v("02")]),_c('h1',{staticClass:"m-title"},[_vm._v("智能时代，为您保驾护航")]),_c('div',{staticClass:"slogn"},[_vm._v("IOS/Android APP、 H5")]),_c('ul',{staticClass:"detail-list"},[_c('li',[_vm._v("IOS/Anroid 应用")]),_c('li',[_vm._v("移动端H5")]),_c('li',[_vm._v("响应式网站")])])]),_c('div',{staticClass:"ide-fe"},[_c('div',{staticClass:"p-m"}),_c('div',{staticClass:"p-1"}),_c('div',{staticClass:"p-2"}),_c('div',{staticClass:"p-3"}),_c('div',{staticClass:"p-4"}),_c('div',{staticClass:"p-5"}),_c('div',{staticClass:"p-6"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"init-layer"},[_c('div',{staticClass:"index-num"},[_vm._v("02")]),_c('h1',{staticClass:"m-title"},[_vm._v("移动端")]),_c('p',{staticClass:"slogn"},[_vm._v("APP、 H5、落地页")]),_c('div',{staticClass:"icon-d"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-layer"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"index-num"},[_vm._v("03")]),_c('h1',{staticClass:"m-title"},[_vm._v("一站式搭建微信全生态")]),_c('div',{staticClass:"slogn"},[_vm._v("涵盖微信全生态")]),_c('ul',{staticClass:"detail-list"},[_c('li',[_vm._v("公众号定制")]),_c('li',[_vm._v("小程序")]),_c('li',[_vm._v("小游戏")]),_c('li',[_vm._v("推广活动H5")])])]),_c('div',{staticClass:"ide-fe"},[_c('div',{staticClass:"p-m"}),_c('div',{staticClass:"p-1"}),_c('div',{staticClass:"p-2"}),_c('div',{staticClass:"p-3"}),_c('div',{staticClass:"p-4"}),_c('div',{staticClass:"p-5"}),_c('div',{staticClass:"p-6"}),_c('div',{staticClass:"p-7"}),_c('div',{staticClass:"p-8"}),_c('div',{staticClass:"p-9"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"init-layer"},[_c('div',{staticClass:"index-num"},[_vm._v("03")]),_c('h1',{staticClass:"m-title"},[_vm._v("微信产品")]),_c('p',{staticClass:"slogn"},[_vm._v("公众号、小程序、小游戏、活动页")]),_c('div',{staticClass:"icon-d"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-layer"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"index-num"},[_vm._v("04")]),_c('h1',{staticClass:"m-title"},[_vm._v("品牌运营, 让用户印象深刻")]),_c('div',{staticClass:"slogn"},[_vm._v("更多用户发现您的产品")]),_c('ul',{staticClass:"detail-list"},[_c('li',[_vm._v("品牌 LOGO VI")]),_c('li',[_vm._v("线下活动")]),_c('li',[_vm._v("设计物料")])])]),_c('div',{staticClass:"ide-fe"},[_c('div',{staticClass:"p-m"}),_c('div',{staticClass:"p-1"}),_c('div',{staticClass:"p-2"}),_c('div',{staticClass:"p-3"}),_c('div',{staticClass:"p-4"}),_c('div',{staticClass:"p-5"}),_c('div',{staticClass:"p-6"}),_c('div',{staticClass:"p-7"}),_c('div',{staticClass:"p-8"}),_c('div',{staticClass:"p-9"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"init-layer"},[_c('div',{staticClass:"index-num"},[_vm._v("04")]),_c('h1',{staticClass:"m-title"},[_vm._v("品牌运营")]),_c('p',{staticClass:"slogn"},[_vm._v("VI、宣传、活动")]),_c('div',{staticClass:"icon-d"})])
}]

export { render, staticRenderFns }