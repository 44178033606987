<template>
  <div id="site-wrap">
    <div class="site-main">
      <div class="header fx-h-se">
        <div class="logo">
          <div
            class="ol lw"
            v-if="(isMobile || showWhite) && curNavId === 1"
          ></div>
          <div class="ol lb" v-else></div>
        </div>
        <ul class="nav fx-h-l" :class="{ white: isMobile && curNavId === 1 }">
          <li
            class="fx-c"
            v-for="item in nav"
            :key="item.id"
            :class="{ cur: item.id === curNavId }"
            @click="changePage(item.id)"
          >
            {{ item.txt }}
          </li>
        </ul>

        <div class="contact-pop">
          <ul class="links fx-v-c">
            <li class="fx-h-l">
              <span class="ico ico-wx"></span>
              <strong>S-Point_wx</strong>
            </li>
          </ul>
        </div>
      </div>
      <!-- 首页 -->
      <transition name="showPage">
        <main-page v-if="curNavId === 1" @changeSlideId="changeS" />
      </transition>

      <!-- 案例 -->
      <transition name="showPage">
        <project-page v-if="curNavId === 2" />
      </transition>

      <!-- 关于我们 -->
      <transition name="showPage">
        <about-page v-if="curNavId === 3" />
      </transition>

      <!-- 联系我们 -->
      <transition name="showPage">
        <contact-page v-if="curNavId === 4" />
      </transition>

      <div class="footer fx-h-se">
        <p>&copy; 2022 Power by S-Point | To Be Special</p>
        <p>website info | Blog</p>
      </div>
    </div>
  </div>
</template>

<script>
import MainPage from "./components/Main.vue";
import ProjectPage from "./components/Project.vue";
import AboutPage from "./components/About.vue";
import ContactPage from "./components/Contact.vue";
export default {
  name: "App",
  data() {
    return {
      showWhite: false,
      isMobile: window.innerWidth < 680,
      nav: [
        {
          id: 1,
          txt: "首页",
        },
        {
          id: 2,
          txt: "案例",
        },
        {
          id: 3,
          txt: "关于",
        },
        {
          id: 4,
          txt: "联系我们",
        },
      ],
      curNavId: 1,
    };
  },
  components: {
    MainPage,
    ProjectPage,
    AboutPage,
    ContactPage,
  },
  methods: {
    changeS(id) {
      this.showWhite = id === 0;
    },
    changePage(id) {
      this.curNavId = id;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 680;
    });
  },
};
</script>

<style lang="less">
@import url("./assets/style/index.less");
</style>
